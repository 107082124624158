import { Link } from "react-router-dom";

function ErrorPage() {
  console.log("Error component rendered");
  return (
    <div className="flex flex-col items-center justify-center max-h-screen h-screen w-full bg-white text-gray-800 cursor-default">
      <p>Oops, this page doesn't exist.</p>
      <Link to="/" className="hover:text-blue-600 hover:cursor-pointer p-8">
        Go home
      </Link>
    </div>
  );
}

export default ErrorPage;
