import React from "react";
import FullBleedButton from "./fullbleedbutton";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export class StatefulForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      submitted: false,
      disabled: true,
    };
  }

  handleSubmit = (e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "recipeform", ...this.state }),
    })
      .then(() =>
        this.setState({
          name: "",
          email: "",
          message: "",
          submitted: true,
        })
      )
      .catch((error) => alert(error));

    e.preventDefault();
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, submitted: false });
    if (
      this.state.name.length > 1 &&
      this.state.email.length > 2 &&
      this.state.message.length > 2
    ) {
      this.setState({ disabled: false }, () => {});
    } else {
      this.setState({ disabled: true }, () => {});
    }
  };

  render() {
    const { name, email, message } = this.state;
    const check = "\u{2714}\u{FE0E}";
    const inputClasses =
      "w-full py-2 px-4 bg-white text-center font-normal italic placeholder:text-red-200 focus:ring focus:ring-red-200 focus:ring-inset focus:outline-0";
    const textareaClasses =
      "min-h-24 w-full py-2 px-4 bg-white text-center font-normal italic placeholder:text-red-200 focus:ring focus:ring-red-200 focus:ring-inset focus:outline-0";
    return (
      <form
        onSubmit={this.handleSubmit}
        className="w-full flex flex-col items-center text-base"
      >
        <div className="h-px w-screen bg-red-200" />
        <label className="flex flex-col items-center pt-4 font-bold w-full">
          <div className="flex flex-row gap-2">Your Name</div>
          <input
            className={inputClasses}
            type="text"
            name="name"
            placeholder="Sue Bird"
            value={name}
            onChange={this.handleChange}
          />
        </label>
        <div className="h-px w-screen bg-red-200 mb-4" />

        <div className="h-px w-screen bg-red-200" />
        <label className="flex flex-col items-center pt-4 font-bold w-full">
          <div className="flex flex-row gap-2">Your Email</div>
          <input
            className={inputClasses}
            type="email"
            name="email"
            placeholder="sue@wnba.com"
            value={email}
            onChange={this.handleChange}
          />
        </label>
        <div className="h-px w-screen bg-red-200 mb-4" />

        <div className="h-px w-screen bg-red-200" />
        <label className="flex flex-col items-center pt-4 font-bold w-full">
          <div className="flex flex-row gap-2">Your Best Advice</div>
          <textarea
            className={textareaClasses}
            name="message"
            value={message}
            placeholder="Ingredients for a good party, a good life, a good wife..."
            onChange={this.handleChange}
          />
        </label>
        <div className="h-px w-screen bg-red-200 mb-4" />
        <FullBleedButton
          type="submit"
          disabled={this.state.disabled || this.state.submitted}
        >
          {this.state.submitted ? check + " Thanks!" : "Submit"}
        </FullBleedButton>
      </form>
    );
  }
}

export default StatefulForm;
