import { MapPin } from "lucide-react";

function Home() {
  return (
    <>
      {/* Content */}
      <div className="h-full min-h-screen w-full overflow-hidden py-12 px-6">
        <div className="bg-[url('./images/EG-logo.svg')] h-24 w-full mb-8 bg-contain bg-no-repeat bg-center" />
        <div className="flex flex-col items-center leading-6 mb-12">
          <p className="text-center mb-1">
            Please save the date for the wedding of
          </p>
          <p className="text-center sm:text-base font-bold mb-1">
            Gabrielle Javitt & Emma Banks
          </p>
          <p className="text-center">Sunday, June 8, 2025</p>
          <div className="text-center flex flex-row items-center">
            <MapPin size={14} className="inline mr-2" />
            Brooklyn, NY
          </div>
        </div>
        <div className="-rotate-[2deg] -rotate-[7deg] hover:-rotate-[9deg] w-[50vw] max-w-full h-[52vh] sm:h-[60vh] mx-auto bg-[url('./images/photo-strip.png')] bg-contain bg-no-repeat self-start bg-center" />
      </div>
    </>
  );
}

export default Home;
