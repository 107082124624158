function RSVP() {
  return (
    <div className="flex flex-col items-center justify-center h-screen w-full">
      <p className="text-2xl mb-8">Coming soon!</p>
      <div className="bg-[url('./images/bean.png')] h-1/3 w-1/2 bg-contain bg-no-repeat bg-center hover:rotate-[2deg]" />
    </div>
  );
}

export default RSVP;
