import { Routes, Route, Outlet, NavLink } from "react-router-dom";
import ErrorPage from "./routes/error-page";
import Home from "./routes/home";
import Recipes from "./routes/recipes";
import RSVP from "./routes/rsvp";
import RecipeSubmitted from "./routes/recipe-submitted";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="rsvp" element={<RSVP />} />
          <Route path="recipes" element={<Recipes />} />
          <Route path="recipe-submitted" element={<RecipeSubmitted />} />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layout() {
  return (
    <div className="min-h-screen w-full bg-white text-red-600 text-sm flex flex-col items-center cursor-default overflow-hidden">
      <nav className="bg-white bg-opacity-90 border-b border-red-600 w-full flex flex-row justify-center fixed top-0">
        <ul className="h-12 border-x border-red-600 flex flex-row items-center text-center italic text-nowrap w-full max-w-[calc(100vw-2rem)] sm:max-w-screen-sm">
          <li className="w-full h-full content-center">
              <NavLink to="/" className="w-full h-full hover:cursor-pointer">
                <button className="w-full h-full hover:underline">
                  home
                </button>
              </NavLink>
          </li>
          <li className="w-full h-full content-center ">
            <NavLink to="/rsvp" className="w-full h-full hover:cursor-pointer">
              <button className="w-full h-full hover:underline">
                rsvp
              </button>
            </NavLink>
          </li>
          <li className="w-full h-full content-center ">
            <NavLink to="/recipes" className="w-full h-full hover:cursor-pointer">
              <button className="w-full h-full hover:underline">
                recipes
              </button>
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="mt-12 w-full h-full border-x border-red-600 max-w-[calc(100vw-2rem)] sm:max-w-screen-sm">
        <Outlet />
      </div>
    </div>
  );
}

export default App;
