import { Link } from "react-router-dom";
import FullBleedButton from "../components/fullbleedbutton";

function RecipeSubmitted() {
  return (
    <div className="flex flex-col items-center gap-4 max-h-screen h-screen w-full bg-white py-8">
      <div className="bg-[url('./images/recipe-card-submitted.svg')] h-1/3 -rotate-[4deg] hover:-rotate-[7deg] w-4/5 mb-8 bg-contain bg-no-repeat bg-center" />
      <p className="mb-8">Thanks for the recipe!</p>

      <FullBleedButton type="submit">
        <Link to="/recipes" className="p-8">
          Take me back →
        </Link>
      </FullBleedButton>
    </div>
  );
}

export default RecipeSubmitted;
