// import Form from "../components/form";
import StatefulForm from "../components/statefulForm";

function Recipes() {
  return (
    <div className="flex flex-col items-center min-h-screen w-full bg-white pt-8">
      <div className="flex flex-col items-center w-5/6 text-center">
        <p className="text-xl font-bold mb-4">Recipes For A Good Time</p>
        <p className="w-full mb-4">
          Give us your best recipes for a long and happy marriage. We're making
          a book!
        </p>
        <p className="w-full mb-8">
          All kinds welcome – life advice, relationship tips, literal recipes
          for food, send it through!
        </p>
        <div className="bg-[url('./images/tuna.png')] h-16 w-16 sm:w-32 sm:h-32 rotate-[6deg] bg-contain bg-no-repeat bg-center mb-8 sm:mb-12 hover:-rotate-[1deg]" />
      </div>

      {/* <Form /> */}
      <StatefulForm />
    </div>
  );
}

export default Recipes;
