function FullBleedButton({
  onClick,
  classes = "",
  type = "button",
  disabled,
  children,
}) {
  const buttonClasses =
    classes +
    "w-screen max-w-[calc(100vw-2rem)] sm:max-w-screen-sm py-8 italic font-bold hover:underline bg-red-600 text-white hover:underline border-x border-red-600 focus:outline-o focus:ring focus:ring-red-200 focus:ring-inset disabled:opacity-75 disabled:hover:no-underline disabled:hover:cursor-not-allowed";
  return (
    <div className="w-screen text-center">
      <div className="h-px w-screen bg-red-200" />
      <button
        disabled={disabled}
        type={type}
        onClick={onClick}
        className={buttonClasses}
      >
        {children}
      </button>
      <div className="h-px w-screen bg-red-200 mb-4" />
    </div>
  );
}

export default FullBleedButton;
